import React from 'react';
import '../index.css';
import twitterIcon from '../icons/iconfinder_social_media_social_media_logo_twitter_2993737.svg';
import discordIcon from '../icons/Discord-Logo-White.svg';
import hashgreenIcon from '../icons/hashgreen.jpeg';
import {NavLink} from 'react-router-dom';

function Footer(){
  return(
    <div className="UpFoot">
        <div className="footer">
            <div className="upsFoot">
                <h3>Content</h3>
                <ul>
                    <li><NavLink to={'/'}>Home</NavLink></li> <br />
                    <li><NavLink to={'/FAQ'}>FAQ</NavLink></li> <br />
                    <li>Play now</li> <br />
                    <li><NavLink to={'/technical'}>Technical Details</NavLink></li> <br />
                </ul>
            </div>
            <div className="upsFoot">
                <h3>General</h3>
                <ul>
                    <li><NavLink to={'/contact'}>Contact</NavLink></li> <br />
                    <li><NavLink to={'/privacy-policy'}>Privacy Policy</NavLink></li> <br />
                </ul>
            </div>

            <div className="upsFoot ">
                <div className="socialFoot">
                    <ul>
                        <li><a target="_blank" href="https://twitter.com/Marmots_io"><img src={twitterIcon} alt=""/></a></li>
                        <li><a target="_blank" href="https://discord.gg/5UcQhGyrzQ"><img src={discordIcon} alt=""/></a></li>
                        <li><a target="_blank" href="https://hash.green/dex/MIO-XCH"><img style={{borderRadius: "10%"}} src={hashgreenIcon} alt=""/></a></li>
                    </ul>
                </div>
            </div>
        </div>
        </div>
  );
}

export default Footer;
